<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- 価格方針 -->
        <v-container>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <h1><heading>料金・会員種別</heading></h1>
                </v-col>
                <v-col cols="12" md="12" align="center">
                    <v-img :src="imagePrice" :key="imagePrice"></v-img>
                </v-col>
                <v-col cols="auto" align="left">
                    <div
                        class="font-weight-light"
                        :class="smAndUp ? 'text-h4 my-4 ' : 'text-body-1 my-4'"
                    >
                        <span class="under"
                            >入会金<span
                                class="font-weight-thin red--text"
                                :class="smAndUp ? 'text-h3' : 'text-h6'"
                                >０</span
                            >円</span
                        >/
                        <span class="under"
                            >事務手数料<span
                                class="font-weight-thin red--text"
                                :class="smAndUp ? 'text-h3' : 'text-h6'"
                                >０</span
                            >円</span
                        >/
                        <span class="under"
                            >解約金<span
                                class="font-weight-thin red--text"
                                :class="smAndUp ? 'text-h3' : 'text-h6'"
                                >０</span
                            >円</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="`10" align="center">
                    <div
                        class="font-weight-light"
                        :class="
                            smAndUp
                                ? 'text-h4 my-4 '
                                : 'text-body-1 font-weight-medium'
                        "
                    >
                        パーソナルトレーニング業界への挑戦。<br />
                        <br />
                        １人でも多くの人がパーソナルトレーニングを<br />
                        長く続けられる、受けられる値段を設定。
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <!-- 価格・会員種別 -->
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="6"
                    md="3"
                    align="center"
                    class="pa-1"
                    v-for="item in prices"
                    :key="item.type"
                >
                    <v-row justify="center" class="ma-auto">
                        <v-col cols="auto pa-0">
                            <p class="mb-0">
                                <span
                                    class="bluetext"
                                    :class="smAndUp ? 'text-h4' : 'text-h5'"
                                    >{{ item.type }}</span
                                >会員
                            </p>
                            <span>{{ item.desc }}</span>
                        </v-col>
                        <v-col cols="12" class="outer-price-board pa-0">
                            <div class="blue lighten-4 ma-2 price-board">
                                <p
                                    :class="
                                        smAndUp ? 'text-body-1' : 'text-body-2'
                                    "
                                    class="my-0"
                                >
                                    {{ item.unit }}
                                </p>
                                <p
                                    :class="
                                        smAndUp ? 'text-body-1' : 'text-body-2'
                                    "
                                    class="my-0"
                                >
                                    {{ item.detail }}
                                </p>
                                <p
                                    :class="
                                        smAndUp ? 'text-body-1' : 'text-body-2'
                                    "
                                >
                                    <span
                                        class="bluetext"
                                        :class="smAndUp ? 'text-h4' : 'text-h6'"
                                        >{{ item.price }}</span
                                    >(税抜)
                                </p>
                            </div>
                        </v-col>
                        <v-col align="left">
                            <div class="text-caption" v-if="!!item.note">
                                ※週1会員or週2会員を3ヶ月以上継続した方専用のアフタープランです。
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col md="8">
                    <link-tag compName="Scene">ジム風景</link-tag>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import LinkTag from '@/components/LinkTag.vue'
import FooterContents from '@/components/FooterContents.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        LinkTag,
        FooterContents,
    },
    data() {
        return {
            prices: [
                {
                    type: '週1',
                    desc: '(月4回プラン)',
                    unit: '月会費',
                    detail: '1回 50分 4,980円×4回',
                    price: ' 19,920円',
                },
                {
                    type: '週2',
                    desc: '(月8回プラン)',
                    unit: '月会費',
                    detail: '1回 50分 4,980円×8回',
                    price: ' 39,840円',
                },
                {
                    type: '卒業生',
                    desc: '(都度プラン)',
                    unit: '1回 50分',
                    detail: '',
                    price: ' 5,980円',
                    note:
                        '※週1会員or週2会員を3ヶ月以上継続した方専用のアフタープランです。',
                },
                {
                    type: 'ビジター',
                    desc: '(都度プラン)',
                    unit: '1回 50分',
                    detail: '',
                    price: ' 6,980円',
                },
            ],
        }
    },
    methods: {},
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '料金・会員種別',
                    disabled: true,
                    href: 'personal-training',
                },
            ]
        },
        imagePrice() {
            return this.smAndUp
                ? '/static/pc_price/pixta_41575292_XL.png'
                : '/static/sp_price/price_img01.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.under {
    background: linear-gradient(transparent 70%, #f6cfcf 70%);
}
.outer-price-board {
    display: table;
    width: 100%;
    min-height: 15vh;
    .price-board {
        display: table-cell;
        vertical-align: middle;
    }
}
.bluetext {
    color: #0034c3;
}
</style>
